/**
 * @ComponentFor MyDetailsPageViewModel
 */
import * as React from 'react';
import { styled } from '@glitz/react';
import { postJson, translate, replaceState } from '@avensia/scope';
import { EpiProperty } from '@avensia/scope-episerver';
import MyDetailsPageViewModel from './MyDetailsPageViewModel.type';
import { minMediumMediaQuery, pixelsToUnit, thick, alto } from 'Shared/Style';
import MyPagesBaseLayout from '../MyPagesBaseLayout';
import FeedbackButton, { Appearance as FeedBackAppearance } from 'Shared/Button/Feedback';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { Checkbox } from 'Shared/Fields/Toggle';
import { ErrorMessage } from 'Shared/FeedbackMessage';

type PropType = MyDetailsPageViewModel;

type StateType = {
  unsubscribeNewsletter: boolean;
  errorMessage: string;
  isSectionOpen: boolean;
};

class MyDetailsPage extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      unsubscribeNewsletter: false,
      errorMessage: '',
      isSectionOpen: false,
    };
  }

  toggleSection = () => {
    return this.setState({ isSectionOpen: !this.state.isSectionOpen });
  };

  toggleCheckbox = () => {
    return this.setState({ unsubscribeNewsletter: !this.state.unsubscribeNewsletter });
  };

  deleteAccount = async () => {
    const hasDeleted = await postJson(
      `/MyDetails/DeleteAccount?unregisterFromNewsletter=${this.state.unsubscribeNewsletter}`,
    );

    if (hasDeleted) {
      replaceState('/', { includeAppShellData: true });
    } else {
      this.setState({ errorMessage: translate('/Account/MyPages/Details/CloseAccountError') });
      return Promise.reject(null);
    }
  };

  render() {
    const { firstName, lastName, email, address1, city, postalCode } = this.props.details;
    const fullName = firstName + ' ' + lastName;

    return (
      <MyPagesBaseLayout {...this.props}>
        <EpiProperty for={this.props.page.mainContent} />
        <DetailsWrapper>
          <Details>
            <DetailsHeader>
              <h4>{translate('/Account/MyPages/Details/UserInformation')}</h4>
            </DetailsHeader>
            <div>{fullName}</div>
            <div>{email}</div>
          </Details>

          <Details>
            <DetailsHeader>
              <h4>{translate('/Account/MyPages/Details/InvoiceAddress')}</h4>
            </DetailsHeader>
            <div>{fullName}</div>
            <div>{address1}</div>
            <div>
              {postalCode} {city}
            </div>
          </Details>
        </DetailsWrapper>

        <ToggleDelete appearance={ButtonAppearance.Bare} onClick={this.toggleSection}>
          {translate('/Account/MyPages/Details/CloseMyAccount')}
        </ToggleDelete>

        {this.state.isSectionOpen && (
          <DeleteAccountWrapper>
            <Warning>{translate('/Account/MyPages/Details/SureCloseAccount')}</Warning>

            <ConfirmMessage>{translate('/Account/MyPages/Details/ConfirmCloseAccount')}</ConfirmMessage>

            <DeleteAccount appearance={FeedBackAppearance.Tertiary} onClick={this.deleteAccount}>
              {translate('/Account/MyPages/Details/CloseMyAccount')}
            </DeleteAccount>

            <UnsubscribeWrapper>
              <CustomChecbox checked={this.state.unsubscribeNewsletter} onChange={this.toggleCheckbox} />
              <CheckboxText onClick={this.toggleCheckbox}>
                {translate('/Account/MyPages/Details/UnregisterNewsletter')}
              </CheckboxText>
            </UnsubscribeWrapper>

            {this.state.errorMessage && (
              <ErrorMessage>{translate('/Account/MyPages/Details/CloseAccountError')}</ErrorMessage>
            )}
          </DeleteAccountWrapper>
        )}
      </MyPagesBaseLayout>
    );
  }
}

export default MyDetailsPage;

const Details = styled.div({
  marginTop: pixelsToUnit(40),
  width: '100%',
  [minMediumMediaQuery]: {
    width: '48%',
  },
});

const DetailsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  [minMediumMediaQuery]: {
    justifyContent: 'space-between',
  },
});

const DetailsHeader = styled.div({
  borderBottom: {
    style: 'solid',
    width: thick,
    color: alto,
  },
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: pixelsToUnit(12),
});

const Warning = styled.p({
  fontWeight: 'bold',
});

const ConfirmMessage = styled.p({
  marginTop: pixelsToUnit(20),
});

const ToggleDelete = styled(Button, {
  display: 'block',
  marginTop: pixelsToUnit(40),
  padding: {
    xy: 0,
  },
});

const DeleteAccount = styled(FeedbackButton, {
  marginTop: pixelsToUnit(23),
  padding: { x: pixelsToUnit(24) },
  textTransform: 'capitalize',
});

const DeleteAccountWrapper = styled.div({
  marginTop: pixelsToUnit(20),
});

const UnsubscribeWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  marginTop: pixelsToUnit(26),
});

const CustomChecbox = styled(Checkbox, {
  marginRight: pixelsToUnit(12),
});

const CheckboxText = styled.span({
  cursor: 'pointer',
});
