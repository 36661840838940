import * as React from 'react';
import { styled } from '@glitz/react';
import MyPagesBaseViewModel from './MyPagesBaseViewModel.type';
import { Main } from 'Shared/PageLayout';
import { minMediumMediaQuery, pixelsToUnit, minLargeMediaQuery } from 'Shared/Style';
import Panel from './components/Panel';
import Content from './components/Content';

const MyPagesBaseLayout = (props: MyPagesBaseViewModel<any> & { children: any }) => {
  const { subMenu, pageTitle, logoutUrl, children } = props;
  return (
    <Main>
      <MypagesWrapper>
        <CustomContent heading={pageTitle} breadcrumbs={props.breadcrumbs}>
          {children}
        </CustomContent>
        <CustomPanel menuList={subMenu} logoutUrl={logoutUrl} />
      </MypagesWrapper>
    </Main>
  );
};

export default MyPagesBaseLayout;

const MypagesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [minMediumMediaQuery]: {
    flexDirection: 'row',
    minHeight: pixelsToUnit(708),
  },
});

const CustomPanel = styled(Panel, {
  marginTop: pixelsToUnit(70),
  [minMediumMediaQuery]: {
    marginRight: pixelsToUnit(26),
    marginLeft: pixelsToUnit(26),
    marginTop: pixelsToUnit(5),
    order: 1,
    width: '15%',
  },
});

const CustomContent = styled(Content, {
  [minLargeMediaQuery]: {
    width: '70%',
  },
  [minMediumMediaQuery]: {
    width: '75%',
    order: 2,
  },
});
