import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { Style } from '@glitz/type';
import BreadCrumbs from 'Shared/Breadcrumbs';
import BreadcrumbsViewModel from 'Shared/Breadcrumbs/BreadcrumbsViewModel.type';
import H1 from 'Shared/Generic/h1';
import { eta, kappa, pixelsToUnit, minMediumMediaQuery, beta } from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = StyledProps &
  ConnectStateType & {
    heading: string;
    children: any;
    breadcrumbs: BreadcrumbsViewModel[];
  };

const MainDetails = (props: PropType) => {
  const defaultCss: Style = {
    padding: { x: pixelsToUnit(10), y: 0 },
  };
  const isMobile = props.currentBreakpoint < 4;

  return (
    <styled.Div css={props.compose(defaultCss)}>
      <CustomBreadcrumbs breadcrumbs={props.breadcrumbs} />
      <H1 css={isMobile && { textAlign: 'center', fontSize: beta }}>{props.heading}</H1>
      {props.children}
    </styled.Div>
  );
};

export default styled(
  connect(state => ({
    currentBreakpoint: state.currentBreakpoint,
  }))(MainDetails),
);

const CustomBreadcrumbs = styled(BreadCrumbs, {
  fontSize: eta,
  letterSpacing: pixelsToUnit(0.2),
  margin: {
    y: pixelsToUnit(10),
    x: 0,
  },
  textAlign: 'center',
  [minMediumMediaQuery]: {
    fontSize: kappa,
    textAlign: 'left',
  },
});
