import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate, postJson, replaceState, clearCacheItem, URLX, Breakpoint } from '@avensia/scope';
import connect from 'Shared/connect';
import NavigationItem from 'Shared/NavigationItem/NavigationItem.type';
import Link from 'Shared/Link';
import { black, thin, thick, alto, pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint?: number;
};

type PropType = {
  menuList: NavigationItem[];
  logoutUrl: string;
} & ConnectStateType &
  StyledProps;

class Panel extends React.Component<PropType, {}> {
  doLogout = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    postJson(this.props.logoutUrl).then(() => {
      this.props.menuList.forEach(menu => clearCacheItem(new URLX(menu.url)));
      replaceState('/', { includeAppShellData: true });
    });
  };

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;

    return (
      <MenuWrapper css={this.props.compose()}>
        {!isMobile && <h4>{translate('/Account/MyPages/Title')}</h4>}
        <styled.Ul css={{ listStyle: 'none' }}>
          {this.props.menuList.map((menu, idx) => (
            <Item
              key={idx}
              css={{
                ...(idx === 0 && {
                  borderTop: {
                    style: 'solid',
                    color: alto,
                    width: thin,
                  },
                  [minMediumMediaQuery]: {
                    borderTop: 'none',
                  },
                }),
              }}
            >
              <CustomLink
                css={{
                  ...(menu.isActive && {
                    color: '#5b3871',
                    fontWeight: 'bold',
                  }),
                }}
                to={menu.url}
              >
                <span>{menu.name}</span>
              </CustomLink>
            </Item>
          ))}
          <Logout onClick={this.doLogout}>{translate('/Account/Logout')}</Logout>
        </styled.Ul>
      </MenuWrapper>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
  )(Panel),
);

const alignListStyle = {
  alignItems: 'center',
  display: 'flex',
};

const MenuWrapper = styled.nav({
  padding: {
    x: pixelsToUnit(10),
    top: pixelsToUnit(14),
  },
  [minMediumMediaQuery]: {
    borderTop: {
      style: 'solid',
      width: thick,
      color: alto,
    },
  },
});

const Item = styled.li({
  borderBottom: {
    style: 'solid',
    color: alto,
    width: thin,
  },
  height: pixelsToUnit(40),
  justifyContent: 'space-between',
  [minMediumMediaQuery]: {
    borderBottom: 'none',
    height: 'inherit',
    marginLeft: pixelsToUnit(14),
    marginTop: pixelsToUnit(5),
    padding: { xy: 0 },
  },
});

const CustomLink = styled(Link, {
  ...alignListStyle,
  color: black,
  height: 'inherit',
  width: '100%',
});

const Logout = styled(Item, {
  ...alignListStyle,
  cursor: 'pointer',
});
